import React from 'react'
import Section from './../Section'
import './styles.scss'
import { Link } from 'gatsby'
import { OutboundLink } from 'gatsby-plugin-google-gtag'
import Drift from 'react-driftjs'

function Footer(props) {
  return (
    <Section color={props.color} size={props.size}>
      <Drift appId={props.driftAppId} />
      <div className="FooterComponent__container container">
        <div className="columns">
          <div className="column is-4 is-4-widescreen">
            <Link className="brand" to="/">
              <div className="brand-icon">
                <img
                  className="FooterComponent__logo"
                  src={props.logo.fluid.src}
                  alt="Logo"
                />
              </div>
            </Link>

            {props.description && (
              <p className="FooterComponent__description">
                {props.description}
              </p>
            )}

            {props.copyright && (
              <p className="FooterComponent__copywrite">{props.copyright}</p>
            )}
          </div>
          <div className="column is-8 is-7-widescreen is-offset-1-widescreen">
            <div className="columns">
              <div className="column is-3">
                <div className="menu">
                  <p className="menu-label">Product</p>
                  <ul className="menu-list">
                    <li>
                      <Link to="/pricing">Pricing</Link>
                    </li>
                    {/* <li>
                      <Link to="/how-it-works">How It Works</Link>
                    </li> */}
                    <li>
                      <Link to="/features">Features</Link>
                    </li>
                    <OutboundLink
                      href="https://help.akutehealth.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Help
                    </OutboundLink>
                    <OutboundLink
                      href="https://akutehealth.statuspage.io/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Status
                    </OutboundLink>
                    <OutboundLink
                      href="https://developer.akutehealth.com"
                      // target="_blank"
                      // rel="noopener noreferrer"
                    >
                      Developer
                    </OutboundLink>
                  </ul>
                </div>
              </div>
              <div className="column is-3">
                <div className="menu">
                  <p className="menu-label">Company</p>
                  <ul className="menu-list">
                    <li>
                      <Link to="/about-us">About</Link>
                    </li>
                    <li>
                      <a
                        // target="_blank"
                        href="/blog"
                        rel="noopener noreferrer"
                      >
                        Blog
                      </a>
                    </li>
                    <li>
                      <a
                        // target="_blank"
                        href="/privacy_policy"
                        rel="noopener noreferrer"
                      >
                        Privacy Policy
                      </a>
                    </li>
                    <li>
                      <a
                        // target="_blank"
                        href="/terms_of_service"
                        rel="noopener noreferrer"
                      >
                        Terms of Service
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="column is-3">
                <div className="menu">
                  <p className="menu-label">Security</p>
                  <ul className="menu-list">
                    <li>
                      <a
                        target="_blank"
                        href="https://trust.akutehealth.com/"
                        rel="noopener noreferrer"
                      >
                        Trust Center
                      </a>
                    </li>
                    <img
                      src="https://akute-app-static-assets.s3.amazonaws.com/soc2_badge.png"
                      alt="SOC2 Compliant"
                      style={{ maxWidth: '65px', margin: '5px' }}
                    />
                    <img
                      src="https://akute-app-static-assets.s3.amazonaws.com/HIPAA+Compliant+badge.png"
                      alt="HIPAA Compliant"
                      style={{ maxHeight: '50px', margin: '5px' }}
                    />
                  </ul>
                </div>
              </div>
              <div className="column is-3">
                <div className="menu">
                  <p className="menu-label">Social</p>
                  <ul className="menu-list">
                    {/* <li>
                      <OutboundLink
                        href="https://www.facebook.com/groups/AkuteHealth"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src="https://uploads.divjoy.com/icon-facebook.svg"
                          alt="Facebook"
                        />
                        Facebook
                      </OutboundLink>
                    </li>
                    <li>
                      <OutboundLink
                        href="https://www.instagram.com/akutehealth/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src=""
                          alt="Instagram"
                        />
                      </OutboundLink>
                    </li>
                    <li>
                      <OutboundLink
                        href="https://twitter.com/AkuteHealth"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src="https://uploads.divjoy.com/icon-twitter.svg"
                          alt="Twitter"
                        />
                        Twitter
                      </OutboundLink>
                    </li> */}
                    {props.socialPages.map((socialPage, index) => {
                      return (
                        <li key={socialPage}>
                          <OutboundLink
                            key={socialPage}
                            // style={{
                            //   color: 'white',
                            //   fontSize: '0.8rem',
                            //   marginRight: '10px',
                            // }}
                            href={socialPage}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={props.socialImages[index].fixed.src}
                              alt={props.socialImages[index].title}
                            />
                            {props.socialImages[index].title}
                          </OutboundLink>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default Footer
