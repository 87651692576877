import React from 'react'
import NavbarContainer from './../NavbarContainer'
import { Link } from 'gatsby'
import SectionButton from './../SectionButton'
import './styles.scss'
import { OutboundLink } from 'gatsby-plugin-google-gtag'
import { getSearchTermFromReferrer } from '../../utils/analytics'

class Navbar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      menuOpen: false,
      indexZeroMenuOpen: false,
    }

    this.setMenuOpen = this.setMenuOpen.bind(this)
    this.setIndexZeroMenuOpen = this.setIndexZeroMenuOpen.bind(this)
  }

  componentDidMount() {
    window.addEventListener('load', this.handleLoad)
  }

  componentWillUnmount() {
    window.removeEventListener('load', this.handleLoad)
  }

  handleLoad = () => {
    const referrer = document.referrer
    const { searchEngine, searchTerm } = getSearchTermFromReferrer(referrer)
    if (searchTerm) {
      console.debug('searchTerm', searchEngine, searchTerm)
    } else {
      console.debug('no searchTerm', searchEngine, searchTerm)
    }
  }

  setMenuOpen = menu => {
    this.setState({
      menuOpen: menu,
    })
  }

  setIndexZeroMenuOpen = menu => {
    this.setState({
      indexZeroMenuOpen: menu,
    })
  }

  render() {
    const brand = this.props.data.edges[0].node.brandLogo
    const links = this.props.data.edges[0].node.links

    return (
      <NavbarContainer spaced={this.props.spaced} color={this.props.color}>
        <div className="container">
          <div className="navbar-brand">
            <div className="navbar-item">
              <Link to="/">
                <img
                  className="image"
                  src={brand.fluid.src}
                  alt="Akute Health"
                />
              </Link>
            </div>
            <div
              className={
                'navbar-burger burger' +
                (this.state.menuOpen ? ' is-active' : '')
              }
              onClick={() => this.setMenuOpen(!this.state.menuOpen)}
            >
              <span />
              <span />
              <span />
            </div>
          </div>
          <div
            className={
              'navbar-menu' + (this.state.menuOpen ? ' is-active' : '')
            }
          >
            <div className="navbar-end">
              {/* <Link className="navbar-item" to="/about">
                About
              </Link>
              <Link className="navbar-item" to="/pricing">
                Pricing
              </Link>
              <Link className="navbar-item" to="/how-it-works">
                How It Works
              </Link>
              <Link className="navbar-item" to="/blog">
                Blog
              </Link>
              <Link
                className="navbar-item"
                to="https://app.akutehealth.com/#/login"
              >
                Sign in
              </Link> */}
              {links.map((link, index) => {
                if (index === 0) {
                  return (
                    <div class="navbar-item">
                      <span class="navbar-link">Industries</span>
                      <div class="dropdown-content">
                        <a href="https://www.akutehealth.com">DPC</a>
                        <a href="https://resources.akutehealth.com/digital-health-demo">
                          Digital Health
                        </a>
                        <a href="https://resources.akutehealth.com/aesthetic-practice-demo">
                          Aesthetic Practices
                        </a>
                      </div>
                    </div>
                  )
                }
                return (
                  <Link
                    className="navbar-item"
                    to={`/${link.replace(/\s+/g, '-').toLowerCase()}`}
                    key={link}
                  >
                    {link}
                  </Link>
                )
              })}
              {/* <div className="navbar-item-button">
                <SectionButton parentColor={this.props.color} size="normal">
                  <OutboundLink
                    href="https://app.akutehealth.com/signup"
                    style={{ color: 'white' }}
                  >
                    Sign Up
                  </OutboundLink>
                </SectionButton>
              </div> */}
              <div className="navbar-item-button">
                <SectionButton parentColor="clear" size="normal">
                  <OutboundLink
                    href="https://app.akutehealth.com/login"
                    style={{ color: 'black' }}
                  >
                    Login
                  </OutboundLink>
                </SectionButton>
              </div>
            </div>
          </div>
        </div>
      </NavbarContainer>
    )
  }
}

export default Navbar
